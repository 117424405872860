export const constant = {
    
    
    //Premium Financing

    REACT_APP_description1:"DIGITAL PROCESS Get your premium financed in less than 5 minutes without the need of additional collateral fully digitally.",
    REACT_APP_description2:"LOW INTEREST RATES Get the most competitive interest rates to help secure your financial future.",
    REACT_APP_description3:"FLEXIBLE REPAYMENT Choose the repayment terms tailored to your financial situation at a pace that suits you best.",
    REACT_APP_description4:"CONTINUATION BENEFITS Keep enjoying policy continuation benefits even after availing Premium Financing as a service.",
    REACT_APP_description5:"With the Abha Card, beneficiaries can enjoy cashless transactions at network hospitals, reducing the financial burden during medical treatments.",
    REACT_APP_description6:"The healthcare and medical services provided under the scheme are free of cost up to ₹5 Lakhs with an Ayushman Bharat Health Account.",
    REACT_APP_description7:"The Abha Card may include provisions for preventive healthcare services, encouraging regular check-ups and wellness programs.",
    REACT_APP_description8:"The cost is covered under the highest package in case of multiple procedures. In the case of a second procedure,the coverage will be reduced.",
    REACT_APP_Insurance_Premium_Title1:"Introducing",
    REACT_APP_Insurance_Premium_Title:"Premium Financing",
    REACT_APP_PREMIUMFINANCE_INFO:"Stressed about paying your renewal premiums? Convert lump-sum payments into easy EMIs with Premium Financing. Our digital process ensures quick approval and competitive rates, allowing you to stay secure without financial stress. Watch the video to learn how it works:",
    REACT_APP_Term_Insurance:"Term Insurance",
    REACT_APP_Investment_Plans:"Investment Plans",
    REACT_APP_Health_Insurance_Plans:"Health Insurance Plans",
    REACT_APP_Renew_Policy:"Renew Policy",
    REACT_APP_Clicktoapply:"I’m Interested",
    REACT_APP_Thankyou:"Thank You!",
    REACT_APP_acknowledgemessage:"Thank you for your interest! We will contact you shortly",
    REACT_APP_keybenefits:"Benefits of Premium Financing",
    REACT_APP_digitalprocess:"Quick Process",
    REACT_APP_digitalprocess_desc:"Immediate loan approval with a fully digital process.",
    REACT_APP_continuation:"Continuation Policy Benefits",
    REACT_APP_continuation_desc:"The Policy continues to grow while you stay protected.",
    REACT_APP_flexiblerepay:"Flexible Repayment",
    REACT_APP_flexiblerepay_desc:"Tailor repayment terms to suit your financial situation.",
    REACT_APP_lowinterest:"Low Interest Rates",
    REACT_APP_lowinterest_desc:"Competitive rates and flexible tenures.",
    REACT_APP_frequentlyasked:"Frequently Asked Questions",
    REACT_APP_gotquestions:"Got questions? we'd love to answer",
    REACT_APP_faq:"Frequently Asked Questions",
    REACT_APP_whatispf:"What is Premium Financing?",
    REACT_APP_whatispfans:"Premium Financing is a service provided by Max Life Insurance that allows policyholders to obtain a short-term loan at competitive interest rates to cover their insurance renewal premiums. It offers a convenient solution for managing premium payments, especially during times of financial strain.",
    REACT_APP_howitworks:"How it works?",
    REACT_APP_howitworkscontent:"The policy holder can then pay the amount in the form of easy EMIs without straining their day finances",
    REACT_APP_eligiblepf:"Who is eligible for Premium Financing?",
    REACT_APP_eligiblepfans:"Eligibility for Premium Financing is subject to the policyholder meeting certain criteria set by Max Life Insurance and the third-party financing provider. The service is currently in an initial experimental phase and is only open to ULIP customers.",
    REACT_APP_availpf:"How many times can I avail Premium Financing?",
    REACT_APP_availpfans:"Premium Financing can be availed by a customer once per policy for the duration chosen by the customer. Upon successful repayment of the current loan, the customer may choose to apply for the service again. The customer can choose to avail the service on multiple policies simultaneously.",
    REACT_APP_durationpf:"What is the duration for which I can avail Premium Financing?",
    REACT_APP_durationpfans:"The tentative duration of the loan is between 3-12 months. The customer may choose to foreclose the loan as per their wish. It is recommended to read the contract document carefully when applying for Premium Financing.",
    REACT_APP_workpf:"How does Premium Financing work?",
    REACT_APP_workpfans:"Premium Financing works by allowing policyholders to apply for a short-term loan through a third-party financing provider approved by Max Life Insurance. Upon approval, the loan amount as specified by the customer is disbursed directly to Max Life to cover the renewal premium. The policyholder then repays the loan, along with any interest, according to the agreed-upon terms.",
    REACT_APP_availpf:"What is the process for availing Premium Financing?",
    REACT_APP_availpfans_main:"Here is the step-by-step process for availing Premium Financing:",
    REACT_APP_availpfans_a:"a) Apply Online: Complete an online application form by providing personal information, policy details, and other required documentation.",
    REACT_APP_availpfans_b:"b) Consent & Verification: Consent to necessary checks and verifications, including credit checks and document verification, to assess eligibility for Premium Financing.",
    REACT_APP_availpfans_c:"c) Contract Agreement & Approval: Review and agree to the terms and conditions outlined by the financing provider, including repayment terms, interest rates, and any applicable fees. Read the document carefully before agreeing.",
    REACT_APP_availpfans_d:"d) Loan Processing & Disbursal: Upon successful verification and agreement, the financing provider processes the loan application and determines the approved loan amount and repayment terms. Once approved, the loan amount is disbursed directly to Max Life Insurance on behalf of the policyholder, covering the renewal premium due.",
    REACT_APP_availpfans_e:"e) Policy Assignment: For the duration of the loan period, the policyholder's insurance policy is conditionally assigned to the financing provider as collateral for the loan, with a surrender clause to be triggered in case of loan defaults.",
    REACT_APP_availpfans_f:"f) Policy Continuation: Throughout the loan repayment period, policyholders continue to enjoy the benefits and coverage provided by their insurance policy, ensuring uninterrupted protection.",
    REACT_APP_availpfans_g:"g) Loan Repayment: Policyholders are responsible for repaying the loan amount, along with any interest and fees, according to the agreed-upon repayment schedule.",
    REACT_APP_availpfans_h:"h) Closure & Re-assignment: Upon successful repayment of the loan, including all associated charges, the policy assignment is released, and the policy returns to its original status with Max Life Insurance.",
    REACT_APP_casedefault:"What happens in case I default?",
    REACT_APP_casedefaultans:"In the event of default on the loan repayment, the financing provider may take the necessary actions as per the terms outlined in the agreement between the policyholder and the financing provider. This may include policy surrender, as the policy will be conditionally assigned to the financing provider for the loan period. It's important to read the contract agreement carefully and be in touch with the financing provider to discuss options and avoid default whenever possible.",
    REACT_APP_disclaimer:"Disclaimer: Premium Financing is currently in an experimental phase and available to select customers only. Max Life does not endorse or guarantee the availability of this service. Customers are free to opt for it at their discretion. Max Life is not liable for any issues arising from its execution.",















    





    
    
    
    
    
    
    
    
    
    
    
    //ABHA
    REACT_APP_API_URL:"https://3j16yiwxp6.execute-api.ap-south-1.amazonaws.com/UAT/abha/abdm/v1",
    REACT_APP_API_URL_AUTHNIT:"/authInit",
    REACT_APP_API_GENERATE_OTP: "/generateOTP",
    REACT_APP_API_VERIFY_OTP: "/verifyOTP",
    REACT_APP_API_RESEND_OTP: "/resendOTP",
    REACT_APP_ALTERNATE_MOBILE_LINK: "/alternateMobileLink",
    REACT_APP_CREATE_HEALTH_ID: "/createHealthId",
    REACT_APP_API_VERIFY_AND_LINK: "/verifyAndLinkMobile",
    REACT_APP_API_URL_VERIFY_AUTH_MOBILE_OTP:"/verifyAuthMobileOTP",
    REACT_APP_UPDATE_HEALTH_ID: "/updateHealthId",
    REACT_APP_API_HEADER_X_API_KEY:"Is4a2to0nP1iNprwuYMZdCBvD7Lgjng4tykNUCSf",
    REACT_APP_GATEWAY_TIMEOUT_API:"Gateway Timeout: Please try again later.",
    REACT_APP_TRUE:"true",
    REACT_APP_MOBILE_OTP:"MOBILE_OTP",
    REACT_APP_txnId:"txnId",
    REACT_APP_token:"token",
    REACT_APP_Enter_ABHA_Number:"Enter ABHA Number",
    REACT_APP_Enter_OTP:"Enter OTP",
    REACT_APP_Enter_ABHA_Address:"Enter ABHA Address",
    REACT_APP_Special_Character_Error:"Special characters are not allowed",
    REACT_APP_ABHA_CardTitle:"Ayushman Bharath Health Account (ABHA)",
    REACT_APP_AadharVerification_CardTitle:"Aadhaar verification",
    REACT_APP_Mobile_Number_Verification_CardTitle:"Mobile Number verification",
    REACT_APP_AbhaAddress_CardTitle:"Set your ABHA Address",
    REACT_APP_SearchAbhaCard_CardTitle:"Search/Download ABHA Card",
    REACT_APP_DownLoadAbhaCard_CardTitle:"Download ABHA Card",
    REACT_APP_RESEND_OTP_TXNID:"resendOtpTxnId",
    REACT_APP_YES:"Yes",
    REACT_APP_Proceed:"Proceed",
    REACT_APP_CHECK_MOBILE_LINKED_WITH_AADHAR:"This mobile number is linked with the Aadhar. Please click proceed to continue",
    REACT_APP_MOBILE_NUMBER:"Mobile Number",
    REACT_APP_INVALID:"invalid",
    REACT_APP_VALID:"valid",
    REACT_APP_GENERATE_OTP:"Generate OTP",
    REACT_APP_ENTER_ADHAR_NUMBER:"Please enter valid Aadhar number",
    REACT_APP_VERIFY_OTP:"Verify OTP",
    REACT_APP_ENTER_ADHAR_NUMBER_PLACEHOLDER:"Enter Aadhar Number",
    REACT_APP_SPECIAL_CHARACTER_MESSAGE:"Special characters are not allowed",
    REACT_APP_VALID_MOBILE_NUMBER:"Please enter valid mobile number",
    REACT_APP_ABHA_INFO:"A unique health ID provided to every citizen to access healthcare services and their personal health records",
    REACT_APP_Track_Application:"Track Application",
    REACT_APP_Customer_Login:"Customer Login",
    REACT_APP_MOBILE_NUMBER_OTP:"An OTP will be sent to mobile number linked to this Aadhaar No.",
    REACT_APP_INPUT_TYPE_PASSWORD: "password",
    REACT_APP_INPUT_TYPE_TEXT: "text",
    REACT_APP_DECLERATION:"I, hereby declare that I am voluntarily sharing my Aadhaar Number / Virtual ID and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of ABHA. I understand that my ABHA can be used and shared for purposes as may be notified by ABDM from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication."
};