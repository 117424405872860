import { Spinner } from "react-bootstrap";

function LoadingSpinner(props) {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{ marginRight: "3%" }}
      hidden={props.show}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default LoadingSpinner;
