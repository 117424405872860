import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { ArrowDownCircle, BootstrapReboot } from "react-bootstrap-icons";

export default function DownloadCard(props) {
  const restart = (e) => {
    window.location.reload();
  };

  return (
    <>
      <Row className="mb-3" xs={1} lg={1}>
        <Col style={{ width: "100%" }}>
          <Image
            src={"data:image/png;base64, " + props.abhaCardDetails.pngCard}
            className="center-block"
            fluid
          />
        </Col>
      </Row>
      <Row className="mb-3" xs={2} lg={2}>
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="w-75 button-color"
            type="button"
            onClick={restart}
          >
            <BootstrapReboot
              color="white"
              size={30}
              style={{ marginRight: "2%" }}
            />
            Restart
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="w-75 button-color"
            type="button"
            href={"data:image/png;base64," + props.abhaCardDetails.pngCard}
            download="abha-card"
          >
            <ArrowDownCircle
              color="white"
              size={30}
              style={{ marginRight: "2%" }}
            />
            Download My Card
          </Button>
        </Col>
      </Row>
    </>
  );
}
