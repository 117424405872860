import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./formcard.css";
import { constant } from "../../constant/constant";
import { Button } from "react-bootstrap";
import { useState } from "react";


function FormCard(props) {
  const [showPopup, setShowPopup] = useState(false);

  const handleKnowMoreClick = () => {
    setShowPopup(true);
  };

  const handleClosepopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="hero-outer">
      <Container className="min-vh-100 hero-section justify-content-center">
        <Row className="align-items-center justify-content-center hero-row">

        <Col className="col-lg-6 col-12">
            <div className="video-outer">
            <video controls controlsList="nodownload" src="hero-video.mp4" autoPlay loop width="100%" height="550" className="text-end hero-video"></video>
            </div>
          </Col>

          <Col className="col-lg-6">
          <p className="content-text1 mb-2 text-center text-sm-center text-md-start text-lg-start">{constant.REACT_APP_Insurance_Premium_Title1}</p>
            <p className="content-heading text-center text-sm-center text-md-start text-lg-start">
              {constant.REACT_APP_Insurance_Premium_Title}
            </p>
            <p className="content-text mb-4 text-center text-sm-center text-md-start text-lg-start">{constant.REACT_APP_PREMIUMFINANCE_INFO}</p>
            <Row xs={1} lg={1}>
              <Col>
                <div className="w-100">
                <Button
                  className={"button-color text-start content-btn hero-btn"}
                  onClick={handleKnowMoreClick}
                >
                  {constant.REACT_APP_Clicktoapply}
                    <i className="bi bi-arrow-right ms-2"></i>
                </Button>
                </div>
                {showPopup && (
                  <div className="popup-overlay" onClick={handleClosepopup}>
                    <div className="popup-content">
                      <p className="text-center fs-4 fw-bold">{constant.REACT_APP_Thankyou}</p>
                      <div>
                        {constant.REACT_APP_acknowledgemessage}
                      </div>
                      <button className="popup-button" onClick={handleClosepopup}>
                        Close
                      </button>
                    </div>
                  </div>
                )}

                
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}

export default FormCard;

