import React from "react";


function PremiumFvideo() {
  return (
    <>
      <video controls autoplay width= "100%" height= "100%" className="rounded">
        <source src="dummy_video.mp4" type="video/mp4"></source> 
      </video>
    </>
  );
}

export default PremiumFvideo;
