import axios from 'axios';
import { constant } from '../constant/constant';

const apiBaseUrl = constant.REACT_APP_API_URL;
const headers= {
  'x-api-key': constant.REACT_APP_API_HEADER_X_API_KEY
};
 
const apiService = async (endpoint, requestBody) => {
  return await axios.post(`${apiBaseUrl+endpoint}`, requestBody,{headers}).then((response) => {
    return response;
  });
};

export default apiService;