import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "./register.css";
import LoadingSpinner from "../common/LoadingSpinner";
import apiService from "../../service/apiService";
import { constant } from "../../constant/constant";
import { Eye, EyeSlashFill } from "react-bootstrap-icons";

export default function Register(props) {
  const [inputValue, setInputValue] = useState("");
  const [tcAccept, setTcAccept] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidOTPInput, setInvalidOTPInput] = useState(false);
  const [generateOtpButtonDisabled, setGenerateOtpButtonDisabled] =
    useState(true);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [aadharNumberInputDisabled, setAadharNumberInputDisabled] =
    useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState(
    constant.REACT_APP_GENERATE_OTP
  );
  const [hideLoader, setHideLoader] = useState(true);
  const [aadharInputMessage, setAadharInputMessage] = useState(
    constant.REACT_APP_ENTER_ADHAR_NUMBER
  );
  const [otpInputMessage, setOtpInputMessage] = useState("");
  const [resendOtpButtonDisabled, setResendOtpButtonDisabled] = useState(false);
  const [showAadhaarInput, setShowAadhaarInput] = useState(false);
  const [aadhaarInputType, setAadhaarInputType] = useState(
    constant.REACT_APP_INPUT_TYPE_PASSWORD
  );

  const onAadharInputChange = (e) => {
    let inputVal = e.target.value;
    if (/^\d+$/.test(inputVal)) {
      if (inputVal.length > 12) {
        return;
      }
      setInputValue(inputVal);
      if (inputVal.length === 12) {
        setInvalidInput(false);
        if (tcAccept) {
          setGenerateOtpButtonDisabled(false);
        }
      } else if (inputVal.length < 12) {
        setInvalidInput(true);
        setAadharInputMessage(constant.REACT_APP_ENTER_ADHAR_NUMBER);
        setGenerateOtpButtonDisabled(true);
      }
    } else if (!inputVal) {
      setInputValue("");
      setInvalidInput(false);
      setGenerateOtpButtonDisabled(true);
    }
  };

  const onOTPInputChange = (e) => {
    if (e.target.value.length <= 6) {
      setOtpInput(e.target.value);
      setInvalidOTPInput(false);
    }
  };

  const tcCheckbox = (e) => {
    if (e.target.checked) {
      setTcAccept(true);
      if (inputValue !== "" && !invalidInput)
        setGenerateOtpButtonDisabled(false);
    } else {
      setTcAccept(false);
      setGenerateOtpButtonDisabled(true);
    }
  };

  const resendOtp = (e) => {
    setResendOtpButtonDisabled(true);
    setInvalidOTPInput(false);

    let request_body = {
      txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
      resendOtp: constant.REACT_APP_TRUE,
    };
    apiService(constant.REACT_APP_API_RESEND_OTP, request_body)
      .then((response) => {
        if (response.data) {
          if (response.data.errorMessage) {
            setInvalidOTPInput(true);
            setOtpInputMessage(response.data.errorMessage);
          } else {
            sessionStorage.setItem(
              constant.REACT_APP_txnId,
              response.data.txnId
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setResendOtpButtonDisabled(false);
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setHideLoader(false);
    setGenerateOtpButtonDisabled(true);
    if (verifyOtp) {
      let request_body = {
        txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
        otp: otpInput,
        verifyOtp: constant.REACT_APP_TRUE,
      };
      apiService(constant.REACT_APP_API_VERIFY_OTP, request_body)
        .then((response) => {
          if (response.data) {
            if (response.data.errorMessage) {
              setInvalidOTPInput(true);
              setOtpInputMessage(response.data.errorMessage);
            } else {
              sessionStorage.setItem(
                constant.REACT_APP_txnId,
                response.data.txnId
              );
              props.setMobileVerification();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setHideLoader(true);
          setGenerateOtpButtonDisabled(false);
        });
    } else {
      let request_body = {
        aadhaar: inputValue,
        generateOtp: constant.REACT_APP_TRUE,
      };
      apiService(constant.REACT_APP_API_GENERATE_OTP, request_body)
        .then((response) => {
          if (response.data) {
            if (response.data.errorMessage) {
              setInvalidInput(true);
              setAadharInputMessage(response.data.errorMessage);
            } else {
              sessionStorage.setItem(
                constant.REACT_APP_txnId,
                response.data.txnId
              );
              setVerifyOtp(true);
              setAadharNumberInputDisabled(true);
              setSubmitButtonLabel(constant.REACT_APP_VERIFY_OTP);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setHideLoader(true);
          setGenerateOtpButtonDisabled(false);
        });
    }
  };

  const showOrHideAadhaarInput = (e) => {
    setAadhaarInputType(
      showAadhaarInput
        ? constant.REACT_APP_INPUT_TYPE_PASSWORD
        : constant.REACT_APP_INPUT_TYPE_TEXT
    );
    setShowAadhaarInput((prev) => !prev);
  };

  return (
    <Form onSubmit={submitForm}>
      <Row className="mb-3" xs={1} lg={1}>
        <Form.Group as={Col} className="mb-2">
          <p className="content-text fw-bold">
            {constant.REACT_APP_MOBILE_NUMBER_OTP}
          </p>
        </Form.Group>
        <Form.Group as={Col} className="mb-2">
          <InputGroup>
            <Form.Control
              type={aadhaarInputType}
              placeholder={constant.REACT_APP_ENTER_ADHAR_NUMBER_PLACEHOLDER}
              isInvalid={invalidInput}
              onChange={onAadharInputChange}
              value={inputValue}
              autoComplete="off"
              required
              aria-label="aadhaar-input"
              aria-describedby="aadhaar-number-input"
              disabled={aadharNumberInputDisabled}
            />
            <InputGroup.Text
              id="aadhaar-number-input"
              onClick={showOrHideAadhaarInput}
            >
              {showAadhaarInput ? <Eye /> : <EyeSlashFill />}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {aadharInputMessage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-2"></Form.Group>
      </Row>
      {verifyOtp ? (
        <Row className="mb-4" xs={1} lg={1}>
          <Col>
            <Form.Group as={Col} className="mb-2">
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  placeholder={constant.REACT_APP_Enter_OTP}
                  isInvalid={invalidOTPInput}
                  onChange={onOTPInputChange}
                  value={otpInput}
                  autoComplete="off"
                  required
                  minLength={6}
                  maxLength={6}
                />
                <Button
                  variant="outline-primary"
                  className="outline-button-color"
                  disabled={resendOtpButtonDisabled}
                  onClick={resendOtp}
                >
                  Resend OTP
                </Button>
                <Form.Control.Feedback type="invalid">
                  {otpInputMessage}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mb-3" xs={1} lg={1}>
        <Col className="d-flex">
          <Button
            variant="primary"
            className="w-100 button-color"
            type="submit"
            disabled={generateOtpButtonDisabled}
          >
            <LoadingSpinner show={hideLoader} />
            {submitButtonLabel}
          </Button>
        </Col>
      </Row>
      <Row className="mb-2" xs={1} lg={1}>
        <Col className="d-flex">
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              checked={tcAccept}
              onChange={tcCheckbox}
              required
            />
          </Form.Group>
          <div
            className="overflow-y-scroll"
            style={{ height: "9rem", marginLeft: "2%" }}
          >
            {constant.REACT_APP_DECLERATION}
          </div>
        </Col>
      </Row>
    </Form>
  );
}
