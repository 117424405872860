import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./formcard.css";
import "./abhacardcontent.css";
import { constant } from "../../constant/constant";

const AbhaCardContent = () => {
  return (
    <>

      <div className="section">
        <Container>
          <Row xs={1} md={1} lg={1}>

            <div className="col-lg-12">
              <div className="faq-section text-center">
                <div className="faq-content bottom-border text-start">
                  <h6>{constant.REACT_APP_whatispf}</h6>
                  <p>{constant.REACT_APP_whatispfans}</p>
                </div>
                <div className="faq-content faq-color text-start">
                  <h6>{constant.REACT_APP_workpf}</h6>
                  <p>{constant.REACT_APP_workpfans}</p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      <div className="section-bg mini-section-bg">
        <div>
          <Container>
            <Row>
              <div className="mini-section-outer my-4">
                <div className="mini-section-heading faq-content bottom-border">
                  <h6>{constant.REACT_APP_keybenefits}</h6>
                </div>
                <div className="mini-section">
                  <div className="content">
                    <div>
                      <i className="bi bi-globe"></i>
                      <h6>{constant.REACT_APP_digitalprocess}</h6>
                      <p>{constant.REACT_APP_digitalprocess_desc}</p>
                    </div>
                  </div>
                  <div className="content">
                    <div>
                      <i className="bi bi-list-stars"></i>
                      <h6>{constant.REACT_APP_continuation}</h6>
                      <p>{constant.REACT_APP_continuation_desc}</p>
                    </div>
                  </div>
                  <div className="content">
                    <div>
                      <i className="bi bi-cash-coin"></i>
                      <h6>{constant.REACT_APP_flexiblerepay}</h6>
                      <p>{constant.REACT_APP_flexiblerepay_desc}</p>
                    </div>
                  </div>
                  <div className="content">
                    <div>
                      <i className="bi bi-percent"></i>
                      <h6>{constant.REACT_APP_lowinterest}</h6>
                      <p>{constant.REACT_APP_lowinterest_desc}</p>
                    </div>
                  </div>


                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>

      <div className="section">
        <Container>
          <Row>
            <div className="mb-3">
              <div className="quest-heading-small">
                <h6>{constant.REACT_APP_frequentlyasked}</h6>
              </div>

              <div className="quest-heading bottom-border text-center">
                <h2>{constant.REACT_APP_gotquestions}</h2>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="contact-img text-end">
                <img src="contact.png" alt="img" width="476" height="476" />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      {constant.REACT_APP_whatispf}
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_whatispfans}</div>
                  </div>
                </div> */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      {constant.REACT_APP_eligiblepf}
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_eligiblepfans}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      {constant.REACT_APP_availpf}
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_availpfans}</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      {constant.REACT_APP_durationpf}
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_durationpfans}</div>
                  </div>
                </div>
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      {constant.REACT_APP_workpf}
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_workpfans}</div>
                  </div>
                </div> */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                      {constant.REACT_APP_availpf}
                    </button>
                  </h2>
                  <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_availpfans_main}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_a}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_b}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_c}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_d}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_e}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_f}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_g}</div>
                    <div className="accordion-body">{constant.REACT_APP_availpfans_h}</div>



                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                      {constant.REACT_APP_casedefault}
                    </button>
                  </h2>
                  <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">{constant.REACT_APP_casedefaultans}</div>
                  </div>
                </div>
              </div>

            </div>
            <div className="disclamer-section">
              <p>{constant.REACT_APP_disclaimer}</p>
            </div>
          </Row>

        </Container>

      </div>

    </>
  );

};
export default AbhaCardContent;
