import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import apiService from "../../service/apiService";
import { constant } from "../../constant/constant";

export default function AbhaAddress(props) {
  const [abdmAddress, setAbdmAddress] = useState("");
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidInputMessage, setInvalidInputMessage] = useState("");
  const [completeRegistrationButton, setCompleteRegistrationButton] =
    useState(true);
  const [hideLoader, setHideLoader] = useState(true);

  const goToDownloadAbhaForm = (e) => {
    e.preventDefault();
    let request_body = {
      healthId: abdmAddress,
      token: sessionStorage.getItem(constant.REACT_APP_token),
      updateHealthData: constant.REACT_APP_TRUE,
    };
    setHideLoader(false);
    setCompleteRegistrationButton(true);
    apiService(constant.REACT_APP_UPDATE_HEALTH_ID, request_body)
      .then((response) => {
        if (response.data) {
          if (response.data.errorMessage) {
            setInvalidInput(true);
            setInvalidInputMessage(response.data.errorMessage);
          } else {
            props.setDownloadAbhaForm(response.data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setHideLoader(true);
        setCompleteRegistrationButton(false);
      });
  };

  const setAbdmAdress = (e) => {
    let address = e.target.value.toLowerCase();
    let n = address.length;
    if (address && n > 0) {
      if (/^[a-zA-Z0-9]*$/.test(address)) {
        setAbdmAddress(address);
        setInvalidInput(false);
        setCompleteRegistrationButton(false);
      } else {
        setInvalidInputMessage(constant.REACT_APP_SPECIAL_CHARACTER_MESSAGE);
        setInvalidInput(true);
        setCompleteRegistrationButton(true);
      }
    } else {
      setAbdmAddress(address);
      setInvalidInputMessage("");
      setInvalidInput(false);
      setCompleteRegistrationButton(true);
    }
  };

  return (
    <Form onSubmit={goToDownloadAbhaForm}>
      <Row className="mb-3" xs={1} lg={1}>
        <Form.Group as={Col} className="mb-2">
          <p className="content-text fw-bold">
            Only alphabets and numericals are allowed. For ex - johndoe123
          </p>
        </Form.Group>
        <Form.Group as={Col} className="mb-2">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder=""
              value={abdmAddress}
              onChange={setAbdmAdress}
              isInvalid={invalidInput}
              aria-label="abdm-address"
              aria-describedby="basic-addon1"
              autoComplete="off"
              maxLength={14}
            />
            <InputGroup.Text id="basic-addon1">@abdm</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {invalidInputMessage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-2"></Form.Group>
      </Row>
      <Row className="mb-2" xs={1} lg={1}>
        <Col>
          <Button
            variant="primary"
            className="w-100 button-color"
            type="submit"
            disabled={completeRegistrationButton}
          >
            <LoadingSpinner show={hideLoader} />
            Complete Registration
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
