import React, { useState } from 'react';
import './Header.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { constant } from "../../constant/constant";
function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg bg-white shadow-sm">
          <div className="container">
            <div className="navbar-brand">

              <img src="https://www.maxlifeinsurance.com/static-page/assets/homepage/maxlogo.svg" alt="" width="80" height="auto" />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-black gap-4">
                <li className="nav-item nav-container">
                  <div className="nav-link label-color ">

                    {constant.REACT_APP_Term_Insurance}

                    <i className="bi bi-chevron-down"></i>
                  </div>
                  <div className="mega-menu">
                    <div className="content">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 p-0">
                            <div className="sub-content-row">
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/premium-calculator?from=home" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-calculator"></i>
                                    </div>
                                    <div>
                                      <h6>Term Plan Calculator</h6>
                                      <p>
                                        Calculate
                                        necessary coverage amount as per your demands to
                                        secure your family</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/critical-illness" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-heart-pulse"></i>
                                    </div>
                                    <div>
                                      <h6>Critical Illness Cover</h6>
                                      <p>A safety net to prepare your family in case of
                                        severe medical emergencies</p>
                                    </div>
                                  </div>
                                </a>
                              </div>

                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/term-plan-with-return-of-premium" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-currency-rupee"></i>
                                    </div>
                                    <div>
                                      <h6>Term with Return of Premium</h6>
                                      <p>Term insurance that ensures a maturity benefit
                                        upon the policyholder's survival</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/claim-settlement-ratio" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-gear"></i>
                                    </div>
                                    <div>
                                      <h6>Claim Settlement Ratio</h6>
                                      <p>Take a look at your insurance provider's history
                                        of settling insurance claims</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/smart-total-elite-protection-plan" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-umbrella"></i>
                                    </div>
                                    <div>
                                      <h6>Smart Total Elite Protection Plan</h6>
                                      <p>Term Insurance that help step up your family's
                                        protection &</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/nri-plans/nri-term-plans" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-people"></i>
                                    </div>
                                    <div>
                                      <h6>2 Crore Term Insurance Plan</h6>
                                      <p>Term Plan with a higher sum assured at an
                                        affordable premium</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/2-crore-term-insurance" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-airplane"></i>
                                    </div>
                                    <div>
                                      <h6>NRI Term Plan</h6>
                                      <p>Get Affordable Term Insurance Plans specially
                                        customized for Non-Resident Indians</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/term-insurance-plans/1-crore-term-insurance" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-people"></i>
                                    </div>
                                    <div>
                                      <h6>1 Crore Term Insurance</h6>
                                      <p>Get a Term Insurance Plan of 1 Crore at
                                        affordable monthly or annual premiums</p>
                                    </div>
                                  </div>
                                </a>
                              </div>




                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>



                <li className="nav-item nav-container">
                  <div className="nav-link label-color ">
                    {constant.REACT_APP_Investment_Plans} <i className="bi bi-chevron-down"></i>
                  </div>
                  <div className="mega-menu">
                    <div className="content">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 p-0">
                            <div className="sub-content-row">
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-calculator"></i>
                                    </div>
                                    <div>
                                      <h6>Investment Calculator</h6>
                                      <p>
                                        Systematic savings, tailored as per your future goals and risk tolerance.</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/insurance-calculators/tax-calculator" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-heart-pulse"></i>
                                    </div>
                                    <div>
                                      <h6>Tax Calculator</h6>
                                      <p>Systematic savings, tailored as per your future goals and risk tolerance.</p>
                                    </div>
                                  </div>
                                </a>
                              </div>

                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/ulip-plans" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-currency-rupee"></i>
                                    </div>
                                    <div>
                                      <h6>ULIP Plan</h6>
                                      <p>Single Plan, Dual Benefits. Comprehensive financial security solution with ULIP</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/savings-and-income-plans" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-gear"></i>
                                    </div>
                                    <div>
                                      <h6>Guaranteed Savings Plan</h6>
                                      <p>Secure your future with assured financial support and guaranteed savings plan</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/smart-wealth-plan" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-umbrella"></i>
                                    </div>
                                    <div>
                                      <h6>Guaranteed Income Plan
                                      </h6>
                                      <p>TPlans designed to deliver on the promise of a financially secure future</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/tax-saving-investments" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-people"></i>
                                    </div>
                                    <div>
                                      <h6>Tax Saving Investments</h6>
                                      <p>Investment opportunities that enable financial security and tax savings</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/nri-plans/nri-investment-plans" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-airplane"></i>
                                    </div>
                                    <div>
                                      <h6>NRI Investment Plans</h6>
                                      <p>Get High-Return Investment Plans specifically customized for Non-Resident Indians</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div className="sub-content">
                                <a href="https://www.maxlifeinsurance.com/investment-plans/ulip-calculator" target="_blank">
                                  <div className="sub-item">
                                    <div className="icon me-3">
                                      <i className="bi bi-people"></i>
                                    </div>
                                    <div>
                                      <h6>ULIP Calculator</h6>
                                      <p>Calculate your ULIP Investment Retums Online</p>
                                    </div>
                                  </div>
                                </a>
                              </div>




                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item nav-container">
                  <a className="nav-link label-color " href="https://www.maxlifeinsurance.com/health-insurance-plans" target="_blank">
                    {constant.REACT_APP_Health_Insurance_Plans}
                  </a>
                </li>

                <li className="nav-item nav-container">
                  <a className="nav-link label-color " href="https://www.maxlifeinsurance.com/cs/pay-online" target="_blank">
                    {constant.REACT_APP_Renew_Policy}
                  </a>
                </li>
               

              </ul>
              <div className="nav-item nav-container">
                  <a className="nav-link label-color " href="https://www.maxlifeinsurance.com/cs/login" target="_blank">
                  <Button className="customer-button-color customer-label-color">
                    {constant.REACT_APP_Customer_Login}
                    </Button>
                  </a>
                </div>
            </div>




          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;







